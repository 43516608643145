<template>
  <div class="login-box fade-in">
    <div class="login-logo">
      <a v-bind:href="website">
        <img v-bind:src="img_banner" />
      </a>
    </div>
    <div class="login-box-body">
      <template v-if="showError">
        <p class="login-box-msg text-warning">
          <i class="fa fa-exclamation-triangle"></i>
          {{ translatedError }}
        </p>
      </template>
      <template v-if="showOtp">
        <p class="login-box-msg">
          <i class="fa fa-info-circle"></i>
          {{ translatedError }}
        </p>
      </template>

      <template v-if="showLogin">
        <p class="login-box-msg">
          {{ $t("sign_in_to_start_your_session") }}
        </p>
      </template>

      <form
        action="/dashboard"
        accept-charset="UTF-8"
        method="post"
        autocomplete="off"
        v-on:submit.stop.prevent="onSubmit"
      >
        <template v-if="showLogin">
          <div class="form-group has-feedback">
            <input
              autocomplete="off"
              name="usr"
              type="text"
              style="display: none"
              value=""
            />
            <input
              name="usr"
              type="text"
              class="form-control"
              :placeholder="$tc('email')"
              required
              v-model="usr"
              ref="usr"
              autocomplete="off"
              v-bind:disabled="isLoading"
              data-testid="email"
            />
            <span class="glyphicon glyphicon-user form-control-feedback"></span>
          </div>
          <div class="form-group has-feedback">
            <input
              autocomplete="off"
              name="pwd"
              type="password"
              style="display: none"
            />
            <input
              name="pwd"
              :type="vwd ? 'text' : 'password'"
              class="form-control"
              :placeholder="$tc('password')"
              required
              v-model="pwd"
              autocomplete="off"
              v-bind:disabled="isLoading"
              data-testid="password"
            />
            <span
              class="form-control-feedback"
              :class="vwd ? 'fa fa-eye' : 'fa fa-eye-slash'"
              @click.prevent="vwd = !vwd"
            ></span>
          </div>
          <div class="row">
            <div class="col-sm-8 hidden-xs">
              <div class="form-check">
                <label
                  class="form-check-label no-select"
                  @click.stop.prevent="save = !save"
                >
                  <i
                    :class="
                      save
                        ? 'fa fa-check-square text-primary'
                        : 'fa fa-square-o'
                    "
                  ></i>
                  <span>
                    {{ $t("keep_me_connected") }}
                  </span>
                </label>
              </div>
            </div>
            <!-- /.col -->
            <div class="col-sm-4">
              <button
                type="submit"
                v-bind:disabled="isLoading"
                class="btn btn-primary btn-block"
                data-testid="sign-in"
              >
                {{ $t("sign_in") }}
              </button>
              <div class="hidden-sm hidden-md hidden-lg">
                <div class="form-check">
                  <label
                    class="form-check-label no-select"
                    @click.stop.prevent="save = !save"
                  >
                    <i
                      :class="
                        save
                          ? 'fa fa-check-square text-primary'
                          : 'fa fa-square-o'
                      "
                    ></i>
                    <span>
                      {{ $t("keep_me_connected") }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <!-- /.col -->
          </div>
        </template>
        <template v-if="showOtp">
          <div class="form-group has-feedback">
            <input
              id="otp"
              name="otp"
              type="text"
              class="form-control otp"
              :placeholder="$tc('security_code')"
              required
              v-model="otp"
              ref="otp"
              autocomplete="off"
              :disabled="isLoading"
              @keypress="isNumber($event)"
              @blur="onlyNumbers($event)"
              @change="onlyNumbers($event)"
              maxlength="10"
              data-testid="otp"
            />
            <span class="fa fa-key form-control-feedback"></span>
          </div>
          <div class="row">
            <div class="col-xs-4"></div>
            <div class="col-xs-4">
              <button
                type="button"
                v-bind:disabled="isLoading"
                class="btn btn-default btn-block"
                data-testid="sign-in"
                @click="resetLoginFields()"
              >
                {{ $t("back") }}
              </button>
            </div>
            <div class="col-xs-4">
              <button
                type="submit"
                v-bind:disabled="isLoading"
                class="btn btn-primary btn-block"
                data-testid="sign-in"
              >
                {{ $t("sign_in") }}
              </button>
            </div>
          </div>
        </template>
      </form>
      <!-- /.social-auth-links -->
      <template v-if="showLogin">
        <router-link
          v-if="user_reset_password_page"
          to="/auth/password_reset"
          class="login-links"
          >{{ $t("i_forgot_my_password") }}</router-link
        ><br />
        <router-link v-if="user_sign_up_page" to="/signup" class="login-links">
          {{ $t("create_account") }}
        </router-link>
      </template>
    </div>
    <div class="overlay" v-if="isLoading">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <Version />
  </div>
</template>

<script>
import AuthService from "@/services/auth";
import Version from "@/components/version.vue";
import MixinCountDown from "@/project/mixin-count-down";

import messages from "@/i18n/api-messages.js";

export default {
  name: "FormSignIn",
  i18n: { messages },
  mixins: [MixinCountDown],
  components: {
    Version
  },
  data: function() {
    return {
      vwd: false,
      pwd: "",
      usr: "",
      otp: "",
      login_state: 0,
      LOGIN_STATE_INITIAL: 0,
      LOGIN_STATE_ERROR: 1,
      LOGIN_STATE_OTP_APP: 2,
      LOGIN_STATE_OTP_EMAIL: 3,
      LOGIN_STATE_OTP_BOTH: 4,
      LOGIN_STATE_OTP_INVALID: 5,
      LOGIN_STATE_TOO_MANY_REQUESTS: 6,
      LOGIN_STATE_NO_CONTRACT: 7,
      LOGIN_STATE_NO_PERMISSIONS: 8,
      LOGIN_STATE_NO_CONTRACT_PERMISSIONS: 9,
      save: true
    };
  },
  props: {
    redirect_url: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    isReady: function() {
      return this.$store.getters["dashboard/isReady"];
    },
    img_banner: function() {
      return (
        this.$store.getters["dashboard/property"]("company_banner") ||
        "/static/common/images/loading-login-default.gif"
      );
    },
    website: function() {
      return (
        this.$store.getters["dashboard/property"]("company_website") ||
        "https://www.hitecnologia.com.br"
      );
    },
    loggedUser: function() {
      return this.$store.getters["user/loggedUser"];
    },
    isLoading: function() {
      return this.$store.getters["user/isLoading"];
    },
    showLogin: function() {
      return [
        this.LOGIN_STATE_INITIAL,
        this.LOGIN_STATE_ERROR,
        this.LOGIN_STATE_TOO_MANY_REQUESTS,
        this.LOGIN_STATE_NO_CONTRACT,
        this.LOGIN_STATE_NO_PERMISSIONS,
        this.LOGIN_STATE_NO_CONTRACT_PERMISSIONS,
      ].indexOf(this.login_state) >= 0;
    },
    showError: function() {
      return this.login_state != this.LOGIN_STATE_INITIAL && !this.showOtp;
    },
    showOtp: function() {
      return [
        this.LOGIN_STATE_OTP_APP,
        this.LOGIN_STATE_OTP_EMAIL,
        this.LOGIN_STATE_OTP_BOTH,
        this.LOGIN_STATE_OTP_INVALID
      ].indexOf(this.login_state) >= 0;
    },
    error: function() {
      let err = this.loginError;
      // matches "<word>:<number>"
      let matched = (err ? err?.message || err : "")?.match(/\w+(:\d+)/);
      if (matched) {
        err = err.message.replace(matched[1], "");
      }
      return err;
    },
    errorNumber: function() {
      let err = this.loginError;
      // matches "<word>:<number>"
      let matched = (err ? err?.message || err : "")?.match(/\w+(:\d+)/);
      if (matched) {
        return matched[1].substr(1);
      }
      return null;
    },
    user_sign_up_page() {
      return "user_management" in this.$root.config &&
        this.$root.config.user_management &&
        "user_sign_up_page" in this.$root.config.user_management
        ? this.$root.config.user_management.user_sign_up_page
        : true;
    },
    user_reset_password_page() {
      return "user_management" in this.$root.config &&
        this.$root.config.user_management &&
        "user_reset_password_page" in this.$root.config.user_management
        ? this.$root.config.user_management.user_reset_password_page
        : true;
    },
    loginError() {
      return this.$store.getters["user/loginError"];
    },
    translatedError() {
      if (!this.error) return "";

      if (this.login_state == this.LOGIN_STATE_TOO_MANY_REQUESTS) {
        return this.$tc("rate_limit", this.errorNumber, {
          param: this.formatCountDown(this.errorNumber)
        });
      } else if (this.login_state == this.LOGIN_STATE_NO_CONTRACT) {
        return this.$t("login_no_contract");

      } else if (this.login_state == this.LOGIN_STATE_NO_PERMISSIONS) {
        return this.$t("login_no_permissions_allowed");

      } else if (this.login_state == this.LOGIN_STATE_NO_CONTRACT_PERMISSIONS) {
        return this.$t("login_no_contract_permissions_allowed");

      } else if (this.login_state == this.LOGIN_STATE_OTP_EMAIL) {
        return this.$t("fill_security_code_email");

      } else if (this.login_state == this.LOGIN_STATE_OTP_APP) {
        return this.$t("fill_security_code_app");

      } else if (this.login_state == this.LOGIN_STATE_OTP_BOTH) {
        return this.$t("fill_security_code_both");

      } else if (this.login_state == this.LOGIN_STATE_OTP_INVALID) {
        return this.loginError.message;

      } else if (this.error.message == "login_forbidden_access") {
        return this.$t(this.error.message);

      } else if (this.$te(this.error.message)) {
        return this.$tc(this.error.message, this.formatCountDown(this.errorNumber), {
          param: this.formatCountDown(this.errorNumber)
        });
      }

      return this.$t("unknown_login_error");
    }
  },
  watch: {
    isLoading: function(nVal, oVal) {
      var self = this;
      if (!nVal && oVal) {
        var user = self.loggedUser;
        if (user) {
          if (self.redirect_url) {
            let base =
              document.location.protocol + "//" + document.location.host;
            let redirect_url = self.redirect_url;
            if (redirect_url.indexOf(base) == 0) {
              redirect_url = redirect_url.substr(base.length);
            }
            self.$router.push(redirect_url);
          } else {
            if (user.is_public_access) {
              this.$store.dispatch("user/logout");
              return;
            }
            if (
              user.user_profile &&
              user.user_profile.requires_password_change
            ) {
              self.$router.push("/dashboard/profile");
            } else {
              self.$router.push("/dashboard/search");
            }
          }
        }
      }
    },
    loginError(err) {
      let matched = (err ? err?.message || err : "")?.match(/\w+:(\d+)/);
      if (matched) {
        // matches "<word>:<number>"
        let value = Number(matched[1]);
        if (this.error.message == "login_attempt_limit") {
          value *= 60;
        }
      }

      if (this.usr && this.pwd && err && typeof err.message == "string") {
        if (err.status == 429) {
          this.login_state = this.LOGIN_STATE_TOO_MANY_REQUESTS;

        } else if (err.message.includes("No contract.")) {
          this.login_state = this.LOGIN_STATE_NO_CONTRACT;

        } else if (err.message.includes("No permissions allowed.")) {
          this.login_state = this.LOGIN_STATE_NO_PERMISSIONS;

        } else if (err.message.includes("No contract permissions allowed.")) {
          this.login_state = this.LOGIN_STATE_NO_CONTRACT_PERMISSIONS;

        } else if (err.message.includes("Código de Segurança")) {
          if (err.message.includes("e-mail") && err.message.includes("aplicativo")) {
            this.login_state = this.LOGIN_STATE_OTP_BOTH;
          } else if (err.message.includes("e-mail")) {
            this.login_state = this.LOGIN_STATE_OTP_EMAIL;
          } else if (err.message.includes("aplicativo")) {
            this.login_state = this.LOGIN_STATE_OTP_APP;
          } else if (err.message.includes("inválido")) {
            this.login_state = this.LOGIN_STATE_OTP_INVALID;
          }

          this.otp = "";
          this.focusOTP();
        } else {
          this.login_state = this.LOGIN_STATE_ERROR;
        }
      } else {
        this.login_state = this.LOGIN_STATE_INITIAL;
      }
    }
  },
  methods: {
    onSubmit() {
      if (this.isLoading) return;
      var self = this;
      let payload = {
        keep_alive: self.save
      };
      if (this.usr && this.pwd) {
        payload.email = this.usr;
        payload.password = btoa(this.pwd);
      }
      if (this.otp) {
        payload.otp = this.otp;
      }
      if (!this.isLoading) {
        self.$store.dispatch("user/login", payload).catch(() => { });
      }
    },
    onCountDownEnd() {
      if (this.error.message == "rate_limit") {
        this.onSubmit();
      }
    },
    formatCountDown(value) {
      if (this.error.message == "login_attempt_limit") {
        return Math.ceil(value / 60);
      }
      return value;
    },
    resetLoginFields() {
      this.login_state = this.LOGIN_STATE_INITIAL;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onlyNumbers(evt) {
      evt = evt ? evt : window.event;
      evt.target.value = evt.target.value.replace(/\D/g, "");
    },
    focusOTP() {
      setTimeout(function() {
        document.getElementById("otp").focus();
      }, 100);
    }
  },
  created: function() {
    let auth = new AuthService();
    if (auth.access_token()) {
      this.$store
        .dispatch("user/login", { keep_alive: auth.keepAlive() })
        .catch(() => { });
    } else {
      this.$store.dispatch("user/logout");
    }
  },
  mounted: function() {
    let self = this;
    self.$nextTick(() => this.$refs.usr.focus());
  }
};
</script>

<style scoped>
.login-logo {
  min-height: 70px;
}

.login-logo > a > img {
  width: 100%;
  max-width: 280px;
}

.login-box {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.login-box-body,
.register-box-body {
  border: 1px solid #dcdada;
  padding: 20px;
  border-radius: 4px;
}

.login-box-msg {
  padding: 0 0 20px 0;
}

.form-check span {
  vertical-align: text-bottom;
}

.form-check-label {
  margin-top: 5px;
  margin-bottom: 0;
  white-space: nowrap;
}

.form-check-label > i {
  font-weight: inherit;
  min-width: 14px;
  margin: 0;
}

.login-links {
  line-height: 2em;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.login-box .overlay > .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}

.login-box .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}

.form-control-feedback {
  pointer-events: all;
}
input.otp {
  text-align: center;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
</style>
